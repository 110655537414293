import {
  Card,
  CardIconPosition,
  CardSize,
} from '@templafy/ui/components/card/Card';
import { IconProps } from '@templafy/ui/icons/Icon';
import { FunctionComponent } from 'react';
import { AuthenticationMethodDto } from '../../generated/Contracts';

interface AuthenticationMethodCardBaseProps {
  authenticationMethod: AuthenticationMethodDto;
  subtitle: string;
  icon: FunctionComponent<IconProps>;
}

const getRedirectUrl = (authenticationMethodId: string) => {
  const url = new URL('/auth/callback', window.location.origin);
  url.search = window.location.search;
  url.searchParams.append('authenticationMethodId', authenticationMethodId);
  return url;
};

export const AuthenticationMethodCardBase: FunctionComponent<
  AuthenticationMethodCardBaseProps
> = ({ authenticationMethod, subtitle, icon }) => {
  return (
    <Card
      icon={icon}
      iconPosition={CardIconPosition.Left}
      iconSize="large"
      size={CardSize.Small}
      title={authenticationMethod.displayName}
      subtitle={subtitle}
      style={{ padding: '4px 0px', overflow: 'visible' }}
      testId={authenticationMethod.identifier}
      onClick={() => {
        window.location.assign(getRedirectUrl(authenticationMethod.identifier));
      }}
    />
  );
};
