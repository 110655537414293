import { FunctionComponent } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { useTranslator } from '@templafy/translation';
import { Button } from '@templafy/ui/components/button/Button';
import activationLinkSentImage from '../resources/images/activation_link_sent.png';
import { AuthenticationStandaloneLayout } from './standaloneLayout/AuthenticationStandaloneLayout';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const ActivationLinkSentPage: FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const returnUrl = queryParameters.get('returnUrl') ?? '';
  const translator = useTranslator();

  const redirectToLoginPage = () => {
    window.location.replace(
      returnUrl ?? `/auth/email/login${window.location.search}`,
    );
  };

  return (
    <AuthenticationStandaloneLayout
      title={translator.activationLinkSentTitle()}
      landscapeImage={activationLinkSentImage}
      portraitImage={activationLinkSentImage}
    >
      <Text as="p" style={{ marginBottom: '12px' }}>
        {translator.activationLinkSentMessage()}
      </Text>

      <Button testId="button" action="primary" onClick={redirectToLoginPage}>
        {translator.activationLinkSentPageLoginButton()}
      </Button>
    </AuthenticationStandaloneLayout>
  );
};
