import { FunctionComponent } from 'react';
import { Link } from '@templafy/ui/components/link/Link';
import { Text } from '@templafy/ui/components/text/Text';
import { useTranslator } from '@templafy/translation';
import { NotificationService } from '@templafy/notifications';
import { Api } from '../generated/Api';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const ResetPasswordExpiredPage: FunctionComponent = () => {
  const translator = useTranslator();
  const queryParameters = useQueryParameters();
  const email = queryParameters.get('email') ?? '';

  const sendPasswordResetLink = async () => {
    await Api.Password.requestPasswordReset({ email });
    NotificationService.success({
      title: translator.resetPasswordPageResetLinkSuccessNotificationTitle(),
      description:
        translator.resetPasswordPageResetLinkSuccessNotificationDescription(),
    });
  };

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <Text size="300">
          {translator.resetPasswordPageExpiredPasswordText()}
        </Text>
        <Text size="300" bold>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={sendPasswordResetLink} style={{ cursor: 'pointer' }}>
            {translator.resetPasswordPageExpiredPasswordLink()}
          </Link>
        </Text>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
