import { Outlet } from 'react-router-dom';
import './translation';
import { NotificationContainer } from '@templafy/notifications';
import { getBrowserLanguage, setLocale } from '@templafy/translation';
import { useAsyncEffect } from '@templafy/ui/hooks/useAsyncEffect';
import { ThemeProvider } from '@templafy/ui/theme/ThemeProvider';
import { authenticationTracking } from './tracking/authenticationTracking';

export const App = () => {
  useAsyncEffect(async () => {
    authenticationTracking.trackCurrentPageView();
    const browserLanguage = getBrowserLanguage();
    await setLocale(browserLanguage);
  }, []);

  return (
    <ThemeProvider style={{ height: '100%' }}>
      <Outlet />
      <NotificationContainer />
    </ThemeProvider>
  );
};
