import { FunctionComponent } from 'react';
import { IconTemplafyLargeColor } from '@templafy/ui/icons/IconTemplafyLargeColor';
import { TemplafyLogo } from '@templafy/ui/illustrations/TemplafyLogo';
import { usePageOrientation } from '../../../utilities/usePageOrientation';
import styles from './TemplafyIcon.module.scss';

export const TemplafyIcon: FunctionComponent = () => {
  const isLandscape = usePageOrientation();
  return (
    <div
      className={
        isLandscape ? styles.templafyIconLandscape : styles.templafyIconPortrait
      }
    >
      {isLandscape ? (
        <IconTemplafyLargeColor testId="iconTemplafy" />
      ) : (
        <TemplafyLogo width={150} />
      )}
    </div>
  );
};
