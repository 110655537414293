import { FunctionComponent } from 'react';
import { useTranslator } from '@templafy/translation';
import { Text } from '@templafy/ui/components/text/Text';
import { StandalonePage } from './StandalonePage';
import notFoundImage from '../../resources/images/not_found.png';

export const NotFoundPage: FunctionComponent = () => {
  const translator = useTranslator();

  return (
    <StandalonePage
      title={translator.notFoundPageTitle()}
      landscapeImage={notFoundImage}
      portraitImage={notFoundImage}
    >
      <Text as="p">{translator.notFoundPageDescription()}</Text>
    </StandalonePage>
  );
};
