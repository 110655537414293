import { FunctionComponent } from 'react';
import { useTranslator } from '@templafy/translation';
import { Text } from '@templafy/ui/components/text/Text';
import { Button } from '@templafy/ui/components/button/Button';
import { StandalonePage } from './StandalonePage';
import accessDeniedImage from '../../resources/images/access_denied.png';

type AccessDeniedPageProps = {
  operationId?: string;
  hostProgram?: string;
  onLogOutClick?: () => void;
};

export const AccessDeniedPage: FunctionComponent<AccessDeniedPageProps> = ({
  operationId,
  hostProgram,
  onLogOutClick,
}) => {
  const translator = useTranslator();
  return (
    <StandalonePage
      title={translator.accessDeniedPageTitle()}
      landscapeImage={accessDeniedImage}
      portraitImage={accessDeniedImage}
    >
      <Text as="p">{translator.accessDeniedPageDescription()}</Text>
      <Text as="p">
        {translator.accessDeniedPageSolution({
          hostProgram: hostProgram || translator.accessDeniedPageHostProgram(),
        })}
      </Text>
      {operationId && (
        <Text
          testId="operation"
          size="200"
          color="gray-500"
          style={{ display: 'block' }}
        >
          {translator.operationId({ operationId })}
        </Text>
      )}
      {onLogOutClick && (
        <Button
          testId="button"
          onClick={onLogOutClick}
          action="primary"
          style={{ alignSelf: 'flex-start' }}
        >
          {translator.accessDeniedPageLogOutButton()}
        </Button>
      )}
    </StandalonePage>
  );
};
