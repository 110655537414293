import { FunctionComponent } from 'react';
import { usePageOrientation } from '../../../utilities/usePageOrientation';
import styles from './Illustration.module.scss';

export interface IllustrationProps {
  landscapeImage: string;
  portraitImage: string;
}

export const Illustration: FunctionComponent<IllustrationProps> = ({
  landscapeImage,
  portraitImage,
}) => {
  const isLandscape = usePageOrientation();

  return isLandscape ? (
    <img
      data-testid="illustration"
      className={styles.illustrationLandscape}
      src={landscapeImage}
      alt=""
    />
  ) : (
    <img
      data-testid="illustration"
      className={styles.illustrationPortrait}
      src={portraitImage}
      alt=""
    />
  );
};
