import { FunctionComponent, useRef, useEffect } from 'react';
import { useTranslator } from '@templafy/translation';
import { Button } from '@templafy/ui/components/button/Button';
import { Link } from '@templafy/ui/components/link/Link';
import { Text } from '@templafy/ui/components/text/Text';
import { announceA11YMessage } from '@templafy/ui/utilities/announceA11YMessage';
import { StandalonePage } from './StandalonePage';
import { useDebounce } from '../useDebounce';
import errorImage from '../../resources/images/server_error.png';
import { useQueryParameters } from '@/hooks/useQueryParameters';
import { goToRoot } from '@/utilities/navigation';

export const ErrorPage: FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const operationId = queryParameters.get('operationId') ?? '';
  const loginUrl = queryParameters.get('loginUrl') ?? '';
  const translator = useTranslator();
  useDebounce(
    () => {
      buttonRef.current?.focus();
    },
    [],
    600,
  );

  useEffect(() => {
    announceA11YMessage({
      message: translator.errorPageTitle(),
    });
  }, [translator]);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const supportRequestLink =
    'https://support.templafy.com/hc/en-us/requests/new';
  return (
    <StandalonePage
      title={translator.errorPageTitle()}
      landscapeImage={errorImage}
      portraitImage={errorImage}
    >
      <Text as="p">
        {translator.errorPageSolution({
          link: (link) => (
            <Link
              href={supportRequestLink}
              aria-label={translator.submitSupportRequest()}
            >
              {link}
            </Link>
          ),
        })}
      </Text>
      {operationId && (
        <Text
          testId="operation"
          size="200"
          color="gray-500"
          style={{ display: 'block' }}
        >
          {translator.operationId({ operationId })}
        </Text>
      )}
      <Button
        ref={buttonRef}
        testId="button"
        action="primary"
        onClick={() => {
          if (loginUrl == null) {
            goToRoot();
            return;
          }
          window.location.href = loginUrl;
        }}
        style={{ alignSelf: 'flex-start' }}
        aria-label={translator.errorPageButton()}
      >
        {translator.errorPageButton()}
      </Button>
    </StandalonePage>
  );
};
