import { useTranslator } from '@templafy/translation';
import { Text } from '@templafy/ui/components/text/Text';
import tenantIsNotAvailableImage from '../resources/images/tenant_is_not_available.png';
import { AuthenticationStandaloneLayout } from './standaloneLayout/AuthenticationStandaloneLayout';

export const TenantIsNotAvailablePage: React.FunctionComponent = () => {
  const translator = useTranslator();

  return (
    <AuthenticationStandaloneLayout
      title={translator.tenantIsNotAvailableTitle()}
      landscapeImage={tenantIsNotAvailableImage}
      portraitImage={tenantIsNotAvailableImage}
    >
      <Text as="p">{translator.tenantIsNotAvailableMessage()}</Text>
    </AuthenticationStandaloneLayout>
  );
};
