import { FunctionComponent } from 'react';
import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import { Text } from '@templafy/ui/components/text/Text';

type DialogHeaderPropsProps = {
  title: string;
  subtitle: string;
};

export const DialogHeader: FunctionComponent<DialogHeaderPropsProps> = ({
  title,
  subtitle,
}) => (
  <FlexBox
    flexDirection="column"
    style={{
      padding: '16px 24px',
      borderBottom: '#D9E0E8 1px solid',
    }}
  >
    <Text style={{ marginBottom: '4px' }} size="300" bold color="gray-900">
      {title}
    </Text>
    <Text size="200">{subtitle}</Text>
  </FlexBox>
);
