import { FlexBox } from '@templafy/ui/components/flexBox/FlexBox';
import { FunctionComponent, ReactNode } from 'react';
import { Illustration, IllustrationProps } from './illustration/Illustration';
import { Info } from './info/Info';
import { usePageOrientation } from '../../utilities/usePageOrientation';
import styles from './AuthenticationStandaloneLayout.module.scss';
import { TemplafyIcon } from './templafyIcon/TemplafyIcon';

interface AuthenticationStandaloneLayoutProps {
  title: string;
}

type Props = AuthenticationStandaloneLayoutProps &
  Pick<IllustrationProps, 'landscapeImage' | 'portraitImage'> & {
    children: ReactNode;
  };

export type BaseAuthenticationStandaloneLayoutProps = Props;

export const AuthenticationStandaloneLayout: FunctionComponent<Props> = ({
  title,
  children,
  landscapeImage,
  portraitImage,
}) => {
  const isLandscape = usePageOrientation();
  return (
    <Layout>
      <div
        className={
          isLandscape
            ? styles.authenticationStandaloneLayoutLandscape
            : styles.authenticationStandaloneLayoutPortrait
        }
      >
        <TemplafyIcon />
        <Illustration
          landscapeImage={landscapeImage}
          portraitImage={portraitImage}
        />
        <Info title={title}>{children}</Info>
      </div>
    </Layout>
  );
};

interface LayoutProps {
  children: ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <FlexBox
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      className={styles.background}
    >
      {children}
    </FlexBox>
  );
};
