import { FunctionComponent } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { Button } from '@templafy/ui/components/button/Button';
import { useTranslator } from '@templafy/translation';
import { Link } from '@templafy/ui/components/link/Link';
import { Form } from '@templafy/ui/components/form/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  defaultFormProps,
  getManager,
  useZodDefaults,
} from '@templafy/ui/reactHookForm';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ManagedTextInput } from '@templafy/ui/reactHookForm/ManagedTextInput';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Api } from '../generated/Api';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const ForgotPasswordPage: FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const emailDefaultValue = queryParameters.get('email') ?? undefined;
  const tenantId = queryParameters.get('tenantId') ?? undefined;
  const redirectUri = queryParameters.get('redirect_uri') ?? '';
  const translator = useTranslator();

  useZodDefaults(z);

  const schema = z.object({
    email: z.string().email().nonempty(),
  });

  type FormModel = z.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormModel>({
    defaultValues: {
      email: emailDefaultValue ?? '',
    },
    ...defaultFormProps,
    resolver: zodResolver(schema),
  });

  const manage = getManager(control, schema);

  const submit = handleSubmit(async (data) => {
    try {
      const redirectResponse = await Api.Password.requestPasswordReset({
        ...data,
        tenantId,
        redirectUri,
      });
      window.location.replace(redirectResponse.location);
    } catch {
      return false;
    }
  });

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <Form
          id="forgot-password-page-form"
          onSubmit={submit}
          size="large"
          readOnly={isSubmitting}
        >
          <Text as="h1" size="300">
            {translator.forgotPasswordPageDescription()}
          </Text>
          <Form.Field>
            <Form.Label>{translator.emailFieldLabel()}</Form.Label>
            <ManagedTextInput
              {...manage('email')}
              autoFocus
              type="email"
              placeholder={translator.emailFieldPlaceholder()}
            />
            <Form.ValidationMessage>
              {errors.email?.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Button
            type="submit"
            action="primary"
            loading={isSubmitting}
            size="large"
          >
            {translator.forgotPasswordPageSubmitButton()}
          </Button>
          <Link
            as={ReactRouterLink}
            testId="go-back"
            to={`/email/login?${queryParameters.toString()}`}
            style={{ alignSelf: 'center' }}
          >
            {translator.forgotPasswordPageGoBackLink()}
          </Link>
        </Form>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
