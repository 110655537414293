import { useTranslator } from '@templafy/translation';
import { IconEnvelopeDark } from '@templafy/ui/icons/IconEnvelopeDark';
import { FunctionComponent } from 'react';
import { AuthenticationMethodDto } from '../../generated/Contracts';
import { AuthenticationMethodCardBase } from './AuthenticationMethodCardBase';

interface EmailAuthenticationMethodCardProps {
  authenticationMethod: AuthenticationMethodDto;
}

export const EmailAuthenticationMethodCard: FunctionComponent<
  EmailAuthenticationMethodCardProps
> = ({ authenticationMethod }) => {
  const translator = useTranslator();

  return (
    <AuthenticationMethodCardBase
      authenticationMethod={authenticationMethod}
      subtitle={translator.emailAuthenticationMethodCardSubtitle()}
      icon={IconEnvelopeDark}
    />
  );
};
